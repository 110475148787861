import React from "react"
import styled from "styled-components"
import BorderedBlock from "../Core/BorderedBlock";
import LoginButton from "./LoginButton";
import TextBlock from "../Core/TextBlock";

const LoginContainer = styled.div`
  text-align: center;
`;

const Login = ({authenticate}) => {
  return (
    <BorderedBlock>
      <LoginContainer>
        <LoginButton authenticate={authenticate}/>
        <TextBlock>
          <p><b>Install the app on your GitHub account or organization.</b></p>
          <p>The app will only use the repository required<br/>for the theme deployments.</p>
        </TextBlock>
      </LoginContainer>
    </BorderedBlock>
  );
};

export default Login;
