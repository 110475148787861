import React from "react"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/Core/Seo"
import PrivateRoute from "../../router/PrivateRoute";
import Login from "../../components/Github/Login";
import github from "../../backend/github";

const AuthenticatePage = () => {
  const authenticate = (e) => {
    e.preventDefault();

    github.authenticate()
      .then(({data}) => {
        const {url} = data;

        if (typeof url === "undefined") {
          alert("Error! Please try again.");

        } else {
          window.location.href = url;
        }
      });
  };

  return (
    <Layout>
      <SEO title="Login with GitHub"
           description="Login with GitHub to configure the application"/>
      <Login authenticate={authenticate}/>
    </Layout>
  );
};

function authenticate(props) {
  return <PrivateRoute component={AuthenticatePage} {...props}/>
}

export default authenticate;
